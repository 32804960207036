import React from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

const MapCentralDistribucion = () => {
  return (
    <div style={{ textAlign: "center" }}>
      <div>
        <Grid container spacing={2} item xs={12} style={{ padding: "10px" }}>
          <Grid item md={12} xs={12} style={{ textAlign: "center" }}>
            <h3 className="inline-block m-0 font-medium">
              <Box fontWeight="fontWeightBold">Centro de Distribución</Box>
            </h3>
          </Grid>
        </Grid>
      </div>
      <Grid style={{ padding: 20 }}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3860.5575734008144!2d-90.52643528449416!3d14.624261289787409!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8589a227f185f61f%3A0xd23ec8e1b71a5745!2sDISDEL%2C%20S.A.!5e0!3m2!1ses-419!2sgt!4v1598647859335!5m2!1ses-419!2sgt"
          width="100%"
          height="400"
          frameborder="0"
          allowfullscreen=""
          aria-hidden="false"
          tabindex="0"
        ></iframe>
      </Grid>
      
    </div>
  );
};

export default MapCentralDistribucion;
